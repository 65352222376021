import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import AppUtils from '../../utils/app';

const ManingMonitorComponent = () => {
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [manings, setManings] = useState([]);
    const [filteredManings, setFilteredManings] = useState([]);
    const [loading, setLoading] = useState(true);

    const [totalIn, setTotalIn] = useState(0);
    const [totalOut, setTotalOut] = useState(0);
    const [totalCancel, setTotalCancel] = useState(0);

    const { control, register, handleSubmit } = useForm();

    const navigate = useNavigate();

    useEffect(() => {
        http.get("products").then(res => {
            let p = [];
            for (let prod in res.data) {
                p.push({value: res.data[prod].id, label: res.data[prod].sku + " - " + res.data[prod].name});
            }
            setProducts(p);
        });

        http.get("customers").then(res => {
            let c = [];
            for (let cust in res.data) {
                c.push({value: res.data[cust].id, label: res.data[cust].title + " - " + res.data[cust].name});
            }
            setCustomers(c);
            setLoading(false);
        });
    }, []);

    const onSubmit = (data) => {
        setTotalCancel(0);
        setTotalIn(0);
        setTotalOut(0);
        setLoading(true);

        let params = {};

        if (typeof data.customer_id !== "undefined") {
            params.customer_id = data.customer_id.value;
        }

        if (typeof data.product_id !== "undefined") {
            params.product_id = data.product_id.value;
        }

        const sparams = new URLSearchParams(params);

        http.get("/tracking/?" + sparams.toString()).then(res => {
            res.data.map(man => {
                if (man.tracking_type === "in") {
                    setTotalIn(c => c + man.num_of_products);
                }

                if (man.tracking_type === "out") {
                    setTotalOut(c => c + man.num_of_products);
                }

                if (man.tracking_type === "cancel") {
                    setTotalCancel(c => c + man.num_of_products);
                }
                man.id = AppUtils.idToDocNo(man.id);
            });
            setManings(res.data);
            setFilteredManings(res.data);
            setLoading(false);
        }).catch(res => {
            toast.error(res.data.msg, {duration: 6000});
            setLoading(false);
        });
    };

    const filterTracking = (e) => {
        const searchTerm = e.target.value;

        setFilteredManings(manings.filter((c) => {
            let name = e.target.name.split("##");
            if (name.length === 1) {
                return String(c[name[0]]).toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return String(c[name[0]][name[1]]).toLowerCase().includes(searchTerm.toLowerCase());
            }
        }));
    };


    const getType = (t) => {
        if (t === "out") {
            return "Çıkış";
        } else if (t === "in") {
            return "Giriş";
        } else {
            return "İptal";
        }
    }

    return (
        <>
            {
                loading ?
                    <div id="loading-overlay">
                        <div id="loading-overlay-text">
                            <img src='/loading.gif' />
                        </div>
                    </div> : '' 
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Fason İzleme</h1>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="customer_id" className="col-sm-3 col-form-label">Üretici</label>
                            <div className="col-sm-9">
                                <Controller
                                    control={control}
                                    name="customer_id"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={customers}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="product_id" className="col-sm-3 col-form-label">Ürün</label>
                            <div className="col-sm-9">
                                <Controller
                                    control={control}
                                    name="product_id"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={products}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Görüntüle </button>
                    </form>
                </div>
            </div>
                
            <table className="table" style={{marginTop: "100px"}}>
                <thead>
                    <tr>
                        <th scope="col">Toplam Çıkış</th>
                        <th scope="col">Toplam Giriş</th>
                        <th scope="col">Toplam İade</th>
                        <th scope="col">Beklenen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{totalOut}</td>
                        <td>{totalIn}</td>
                        <td>{totalCancel}</td>
                        <td>{totalOut - totalIn - totalCancel}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table table-hover" style={{marginTop: "100px"}}>
                <thead>
                    <tr>
                        <th scope="col">Belge No</th>
                        <th scope="col">Üretici</th>
                        <th scope="col">Tarih</th>
                        <th scope="col">Ürün</th>
                        <th scope="col">Adet</th>
                        <th scope="col">Tipi</th>
                        <th scope="col">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="text" name="id" onChange={(e) => filterTracking(e)} style={{width: "100px"}}/></td>
                        <td><input type="text" name="customer##name" onChange={(e) => filterTracking(e)} style={{width: "150px"}}/></td>
                        <td></td>
                        <td><input type="text" name="product##name" onChange={(e) => filterTracking(e)} style={{width: "100px"}}/></td>
                        <td colSpan={7}></td>
                    </tr>
                    {
                        filteredManings.map(man => (
                            <tr key={man.id}>
                                <td>{man.id}</td>
                                <td>{man.customer.title}</td>
                                <td>{man.created_date}</td>
                                <td>{man.product.name}</td>
                                <td>{man.num_of_products}</td>
                                <td>{getType(man.tracking_type)}</td>
                                <td>
                                    <Link to={`/dashboard/manufacturings/detail/${AppUtils.docNoToId(man.id)}`} className="btn btn-sm btn-outline-primary">Detay</Link>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    )
}

export default ManingMonitorComponent