import React, { useState, useEffect, useContext } from 'react';
import http from "../../utils/http-client";
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const ProductListComponent = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        http.get("products").then(res => {
            setProducts(res.data);
            setFilteredProducts(res.data);
        });
    }, []);

    const onDeleteClick = (e) => {
        e.preventDefault();
        if (!window.confirm("Silmek istediğine emin misin?")) {
            return false;
        }
        let id = e.currentTarget.getAttribute('data-id');
        http.del("/products/" + id).then(res => {
            toast.success('Ürün silindi!');
            document.getElementById("product-" + id).remove();
        });
    };

    const filterProducts = (e) => {
        const searchTerm = e.target.value;
        setFilteredProducts(products.filter((c) =>
            c[e.target.name].toLowerCase().includes(searchTerm.toLowerCase())
        ));
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Ürünler</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/products/add" className="btn btn-sm btn-outline-secondary">Yeni Ürün</Link>
                </div>
            </div>
            <div className='table-responsive small'>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Ürün Adı</th>
                        <th scope="col">Fiyat</th>
                        <th scope="col">Ürün Tanım</th>
                        <th scope="col">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td><input type="text" name="sku" onChange={(e) => filterProducts(e)} /></td>
                        <td><input type="text" name="name" onChange={(e) => filterProducts(e)} /></td>
                        <td><input type="text" name="price" onChange={(e) => filterProducts(e)} /></td>
                        <td><input type="text" name="description" onChange={(e) => filterProducts(e)} /></td>
                        <td></td>
                    </tr>
                    {
                        filteredProducts.map(product => (
                            <tr key={product.id} id={"product-" + product.id}>
                                <th scope="row">{product.id}</th>
                                <td>{product.sku}</td>
                                <td>{product.name}</td>
                                <td>{product.price ? product.price + " TL" : "-"}</td>
                                <td>{product.description}</td>
                                <td>
                                    <Link to={`/dashboard/products/edit/${product.id}`} className="btn btn-sm btn-outline-primary"><i className="fa-solid fa-pencil"></i></Link>&nbsp;
                                    <a className="btn btn-sm btn-outline-danger" onClick={onDeleteClick} data-id={product.id}><i className="fa-solid fa-trash"></i></a>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
            </div>
        </>
    )
}

export default ProductListComponent