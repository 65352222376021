import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './guards/auth.guard';

import LoginComponent from "./components/login.component";
import DashboardComponent from './components/dashboard.component';

const AppRouter = () => {
    return (
        <Routes>
            <Route exact path='/login' element={<LoginComponent />} />
            <Route element={<AuthGuard />}>
                <Route exact path='/dashboard/*' element={<DashboardComponent />} />
            </Route>
            <Route path='*' element={<DashboardComponent />} />
        </Routes>
    )
}

export default AppRouter;