import React, { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import http from "../utils/http-client";

import HeaderComponent from './header.component';
import SidebarComponent from './side-bar.component';
import ProductListComponent from './product/list';
import ProductAddComponent from './product/add';
import ProductEditComponent from './product/edit';
import ManListComponent from './manufacturer/list';
import ManAddComponent from './manufacturer/add';
import ManEditComponent from './manufacturer/edit';

import { Toaster } from 'react-hot-toast';
import UserListComponent from './user/list';
import UserAddComponent from './user/add';
import UserEditComponent from './user/edit';
import ManingOutListComponent from './manufacturing/out/list';
import ManingOutNewComponent from './manufacturing/out/new';
import ManingDetailComponent from './manufacturing/detail';
import ManingInNewComponent from './manufacturing/in/new';
import ManingInListComponent from './manufacturing/in/list';
import ManingCancelNewComponent from './manufacturing/cancel/new';
import ManingCancelListComponent from './manufacturing/cancel/list';
import ManingMonitorComponent from './manufacturing/monitor';

class DashboardComponent extends React.Component {

  componentDidMount() {
    setInterval(function() {
      http.get("health_check").then(res => {
          console.log("h c");
    });
    }, 1000 * 60 * 5);
  }

  render () {
    return (
      <>
          <div><Toaster/></div>
          <HeaderComponent />
          <div className="container-fluid">
              <div className="row">
                  <SidebarComponent />
                  <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
                      <Routes>
                          <Route path='/products' element={<ProductListComponent />} />
                          <Route path='/products/add' element={<ProductAddComponent />} />
                          <Route path="/products/edit/:productId" element={<ProductEditComponent />} />

                          <Route path='/manufacturers' element={<ManListComponent />} />
                          <Route path='/manufacturers/add' element={<ManAddComponent />} />
                          <Route path='/manufacturers/edit/:customerId' element={<ManEditComponent />} />

                          <Route path='/manufacturings/detail/:id' element={<ManingDetailComponent />} />
                          <Route path='/manufacturings/out' element={<ManingOutListComponent />} />
                          <Route path='/manufacturings/out/new' element={<ManingOutNewComponent />} />

                          <Route path='/manufacturings/in' element={<ManingInListComponent />} />
                          <Route path='/manufacturings/in/new' element={<ManingInNewComponent />} />

                          <Route path='/manufacturings/cancel/new' element={<ManingCancelNewComponent />} />
                          <Route path='/manufacturings/cancel' element={<ManingCancelListComponent />} />

                          <Route path='/manufacturings/monitor' element={<ManingMonitorComponent />} />

                          <Route path='/users' element={<UserListComponent />} />
                          <Route path='/users/add' element={<UserAddComponent />} />
                          <Route path='/users/edit/:id' element={<UserEditComponent />} />
                      </Routes>
                  </main>
          </div>
          </div>
      </>
    )
  }
}

export default DashboardComponent