import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';

const ManAddComponent = () => {

    const { register, handleSubmit } = useForm()
    const navigate = useNavigate();

    const onSubmit = (data) => {
        http.post("/customers", data).then(res => {
            toast.success('Üretici eklendi!');
            navigate("/dashboard/manufacturers");
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Yeni Fason Üretici</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/manufacturers" className="btn btn-sm btn-outline-secondary">Fason Üretici Listesi</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="title" className="col-sm-2 col-form-label">Ünvan</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("title")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Yetkili Ad Soyad</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("name")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="email" className="col-sm-2 col-form-label">E-Posta</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("email")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-sm-2 col-form-label">Telefon</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("phone")} placeholder='Örn: 905329876543'/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="address" className="col-sm-2 col-form-label">Adres</label>
                            <div className="col-sm-10">
                                <textarea className="form-control" rows="3" {...register("address")}></textarea>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Ekle</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ManAddComponent