import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import http from "../../../utils/http-client";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { status_options } from '../../../data/tracking';

const ManingOutNewComponent = () => {
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);

    const { control, register, handleSubmit } = useForm();

    const navigate = useNavigate();

    useEffect(() => {
        http.get("products").then(res => {
            let p = [];
            for (let prod in res.data) {
                p.push({value: res.data[prod].id, label: res.data[prod].sku + " - " + res.data[prod].name});
            }
            setProducts(p);
        });

        http.get("customers").then(res => {
            let c = [];
            for (let cust in res.data) {
                c.push({value: res.data[cust].id, label: res.data[cust].title + " - " + res.data[cust].name});
            }
            setCustomers(c);
        });
    }, []);

    const onSubmit = (data) => {
        if (typeof data.customer_id === "undefined") {
            alert("Üretici seçimi zorunlu!");
            return false;
        }

        if (typeof data.product_id === "undefined") {
            alert("Ürün seçimi zorunlu!");
            return false;
        }


        data.customer_id = data.customer_id.value;
        data.product_id = data.product_id.value;
        data.tracking_type = "out";

        http.post("/tracking", data).then(res => {
            toast.success('Yeni fason çıkış oluşturuldu.');
            navigate("/dashboard/manufacturings/out");
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Yeni Fason Çıkış</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/manufacturings/out" className="btn btn-sm btn-outline-secondary">Fason Çıkış Listesi</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="product_id" className="col-sm-3 col-form-label">Ürün</label>
                            <div className="col-sm-9">
                                <Controller
                                    control={control}
                                    name="product_id"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={products}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="num_of_products" className="col-sm-3 col-form-label">Ürün Adeti</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" {...register("num_of_products")}/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="customer_id" className="col-sm-3 col-form-label">Üretici</label>
                            <div className="col-sm-9">
                                <Controller
                                    control={control}
                                    name="customer_id"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={customers}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="notes" className="col-sm-3 col-form-label">Not</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" rows="3" {...register("notes")}></textarea>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Çıkış Yap</button> 
                    </form>
                </div>
            </div>
        </>
    )
}

export default ManingOutNewComponent