import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';

const ProductEditComponent = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: product,
    });
    const navigate = useNavigate();

    useEffect(() => {
        http.get("products/" + productId).then(res => {
            setProduct(res.data);
            reset(res.data);
        });
    }, [productId, reset]);

    const onSubmit = (data) => {
        http.put("/products/" + productId, data).then(res => {
            toast.success('Ürün değiştirildi!');
            navigate("/dashboard/products");
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Ürün Düzenle</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/products" className="btn btn-sm btn-outline-secondary">Ürün Listesi</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="sku" className="col-sm-2 col-form-label">SKU</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("sku")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Ürün Adı</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("name")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="price" className="col-sm-2 col-form-label">Fiyat</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" {...register("price")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="description" className="col-sm-2 col-form-label">Ürün Tanım</label>
                            <div className="col-sm-10">
                                <textarea className="form-control" rows="3" {...register("description")}>
                                </textarea>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ProductEditComponent