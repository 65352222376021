import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../services/auth.service';

const HeaderComponent = () => {

  const navigate = useNavigate();
  const authUser = authService.getAuthUser();

  const handleLogout = (e) => { 
      authService.logout().then(res => {
        localStorage.removeItem('authUser');
        navigate('/login');
      }); 

  }

  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/dashboard">bolmaksan - fason</a>

    <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className='w-100'> </div>
    <div className="navbar-nav">
      <div className="nav-item text-nowrap">
        {
          authUser
            ?
            <li className="nav-item">
              <Link to={'#'} onClick={(e) => handleLogout(e)} className="nav-link px-3">Çıkış</Link>
            </li>
            :
            <>
            </>
        }
      </div>
    </div>
  </header>
  )
}

export default HeaderComponent