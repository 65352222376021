import React, { useState, useEffect } from 'react';
import http from "../../utils/http-client";
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const ManListComponent = () => {
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);

    useEffect(() => {
        http.get("customers").then(res => {
            setCustomers(res.data);
            setFilteredCustomers(res.data);
        });
    }, []);

    const onDeleteClick = (e) => {
        e.preventDefault();
        if (!window.confirm("Silmek istediğine emin misin?")) {
            return false;
        }
        let id = e.currentTarget.getAttribute('data-id');
        http.del("/customers/" + id).then(res => {
            toast.success('Müşteri silindi!');
            document.getElementById("customer-" + id).remove();
        });
    };

    const filterCustomers = (e) => {
        const searchTerm = e.target.value;
        setFilteredCustomers(customers.filter((c) =>
            c[e.target.name].toLowerCase().includes(searchTerm.toLowerCase())
        ));
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Üreticiler</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/manufacturers/add" className="btn btn-sm btn-outline-secondary">Yeni Üretici</Link>
                </div>
            </div>
            <div className='table-responsive small'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Ünvan</th>
                            <th scope="col">Ad Soyad</th>
                            <th scope="col">E-Posta</th>
                            <th scope="col">Telefon</th>
                            <th scope="col">Adres</th>
                            <th scope="col">Bakiye</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td><input type="text" name="title" onChange={(e) => filterCustomers(e)} /></td>
                            <td><input type="text" name="name" onChange={(e) => filterCustomers(e)} /></td>
                            <td><input type="text" name="email" onChange={(e) => filterCustomers(e)} /></td>
                            <td><input type="text" name="phone" onChange={(e) => filterCustomers(e)} /></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {
                            filteredCustomers.map(customer => (
                                <tr key={customer.id} id={"customer-" + customer.id}>
                                    <th scope="row">{customer.id}</th>
                                    <td><Link to={`/dashboard/manufacturing/monitor?customer=${customer.id}`} title="Fason İzleme">{customer.title}</Link></td>
                                    <td>{customer.name}</td>
                                    <td>{customer.email}</td>
                                    <td>{customer.phone}</td>
                                    <td>{customer.address}</td>
                                    <td>{customer.balance}</td>
                                    <td>
                                        <Link to={`/dashboard/manufacturing/monitor?customer=${customer.id}`} className="btn btn-sm btn-outline-primary" title="Fason İzleme"><i class="fa-solid fa-magnifying-glass-chart"></i></Link>&nbsp;
                                        <Link to={`/dashboard/manufacturers/edit/${customer.id}`} className="btn btn-sm btn-outline-primary" title="Düzenle"><i className="fa-solid fa-pencil"></i></Link>&nbsp;
                                        <a className="btn btn-sm btn-outline-danger" onClick={onDeleteClick} data-id={customer.id} title="Sil"><i className="fa-solid fa-trash"></i></a>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ManListComponent