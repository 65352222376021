import React, { useState, useEffect, useContext } from 'react';
import http from "../../utils/http-client";
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const UserListComponent = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        http.get("users").then(res => {
            setUsers(res.data);
        });
    }, []);

    const onDeleteClick = (e) => {
        e.preventDefault();
        if (!window.confirm("Silmek istediğine emin misin?")) {
            return false;
        }
        let id = e.currentTarget.getAttribute('data-id');
        http.del("/users/" + id).then(res => {
            toast.success('Kullanıcı silindi!');
            document.getElementById("user-" + id).remove();
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Sistem Kullanıcıları</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/users/add" className="btn btn-sm btn-outline-secondary">Yeni Kullanıcı</Link>
                </div>
            </div>
            <div className='table-responsive small'>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kullanıcı Adı</th>
                        <th scope="col">Ad Soyad</th>
                        <th scope="col">E-Posta</th>
                        <th scope="col">Rol</th>
                        <th scope="col">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map(user => (
                            <tr key={user.id} id={"user-" + user.id}>
                                <th scope="row">{user.id}</th>
                                <td>{user.username}</td>
                                <td>{user.name ? user.name : ''} {user.last_name ? "" + user.last_name : ''}</td>
                                <td>{user.email}</td>
                                <td>{user.role === "admin" ? 'Admin' : ''}{user.role === "user" ? 'Kullanıcı' : ''}{user.role === "manager" ? 'Yönetici' : ''}</td>
                                <td>
                                    <Link to={`/dashboard/users/edit/${user.id}`} className="btn btn-sm btn-outline-primary"><i className="fa-solid fa-pencil"></i></Link>&nbsp;
                                    {
                                        user.id !== 1 ? <a className="btn btn-sm btn-outline-danger" onClick={onDeleteClick} data-id={user.id}><i className="fa-solid fa-trash"></i></a> : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
            </div>
        </>
    )
}

export default UserListComponent