import React from 'react';
import { Link } from 'react-router-dom';

class SidebarComponent extends React.Component {
    componentDidMount() {
        document.querySelectorAll('.nav-link').forEach(a => 
            a.addEventListener('click', () => {
                var elems = document.querySelectorAll(".active");
                [].forEach.call(elems, function(el) {
                    el.classList.remove("active");
                });
                a.classList.toggle('active');
            })
        );
    };

    render() {
        return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link"><i className="fa fa-home"></i> Anasayfa</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="collapse" href="#fason-monitor" role="button" aria-expanded="false" aria-controls="fason-monitor">
                                <i class="fa-solid fa-chart-column"></i> Fason İzleme
                            </a>
                        </li>
                        <div class="sidebar-collapse">
                            <div class="collapse" id="fason-monitor">
                                <li className="nav-item">
                                    <Link to="/dashboard/manufacturings/monitor" className="nav-link">Özet</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/dashboard/manufacturings/monitor" className="nav-link">Detaylı</Link>
                                </li>
                            </div>
                        </div>
                        <li className="nav-item">
                            <Link to="/dashboard/manufacturings/out/new" className="nav-link"><i className="fa-solid fa-arrow-right-from-bracket"></i> Fason Çıkış</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/manufacturings/in/new" className="nav-link"><i className="fa-solid fa-arrow-right-to-bracket"></i> Fason Giriş</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/manufacturings/cancel/new" className="nav-link"><i className="fa-solid fa-xmark"></i> Fason İptal</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/manufacturers" className="nav-link"><i className="fa-regular fa-handshake"></i> Üreticiler</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/products" className="nav-link"><i className="fa-solid fa-cubes"></i> Ürünler</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/users" className="nav-link"><i className="fa-solid fa-user"></i> Sistem Kullanıcıları</Link>
                        </li>
                    </ul>

                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Raporlar</span>
                        <a className="link-secondary" href="#" aria-label="Add a new report">
                            <span data-feather="plus-circle"></span>
                        </a>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <span data-feather="file-text"></span>
                                Aylık
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <span data-feather="file-text"></span>
                                Yıllık
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default SidebarComponent