import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import { user_role_options } from '../../data/user';

const UserAddComponent = () => {

    const { register, handleSubmit, control } = useForm()
    const navigate = useNavigate();

    const onSubmit = (data) => {
        if (!window.confirm("Bilgilerin doğruluğundan emin misin?")) {
            return false;
        }
        if (data["password"] !== data["password-re"]) {
            toast.error('Parola ve parola tekrarı uyuşmuyor!');
            return false;
        }
        if (data["password"].length < 8) {
            toast.error('Parola en az 8 karakterden oluşmalıdır!');
            return false;
        }

        data.role = data.role.value;

        http.post("/users/register", data).then(res => {
            toast.success('Kullanıcı eklendi!');
            navigate("/dashboard/users");
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Yeni Kullanıcı</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/users" className="btn btn-sm btn-outline-secondary">Kullanıcı Listesi</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-sm-3 col-form-label">Ad</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" {...register("name")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="last_name" className="col-sm-3 col-form-label">Soyad</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" {...register("last_name")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="email" className="col-sm-3 col-form-label">E-Posta</label>
                            <div className="col-sm-4">
                                <input type="email" className="form-control" {...register("email")} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="status" className="col-sm-3 col-form-label">Rol</label>
                            <div className="col-sm-4">
                                <Controller
                                    control={control}
                                    name="role"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={user_role_options}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <hr />
                        <div className="row mb-3">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Kullanıcı Adı</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" {...register("username")} autoComplete="off" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="password" className="col-sm-3 col-form-label">Parola</label>
                            <div className="col-sm-4">
                                <input type="password" className="form-control" {...register("password")} autoComplete="off" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="password-re" className="col-sm-3 col-form-label">Parola Tekrar</label>
                            <div className="col-sm-4">
                                <input type="password" className="form-control" {...register("password-re")} autoComplete="off" />
                            </div>
                        </div>



                        <button type="submit" className="btn btn-primary">Ekle</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UserAddComponent