import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm } from "react-hook-form"
import toast, { Toaster } from 'react-hot-toast';

const ProductAddComponent = () => {

    const { register, handleSubmit } = useForm()
    const navigate = useNavigate();

    const onSubmit = (data) => {
        http.post("/products", data).then(res => {
            toast.success('Ürün eklendi!');
            navigate("/dashboard/products");
        })
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Yeni Ürün</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/products" className="btn btn-sm btn-outline-secondary">Ürün Listesi</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="sku" className="col-sm-2 col-form-label">SKU</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" {...register("sku")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Ürün Adı</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" {...register("name")} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="description" className="col-sm-2 col-form-label">Ürün Tanım</label>
                            <div className="col-sm-10">
                                <textarea className="form-control" rows="3" {...register("description")}></textarea>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Birim Fiyat</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" {...register("price")} />
                                <small id="priceHelp" className="form-text text-muted">Örn: 1.75 ya da 10</small>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Ekle</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ProductAddComponent