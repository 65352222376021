import React, { useState, useEffect } from 'react';
import './App.css';
import AppRouter from './app.router';
import { LoadingProvider } from './PageLoaderContext';

function App() {
  const [loading, setLoading] = useState(null);
  return (
      <LoadingProvider>
        <AppRouter />
      </LoadingProvider>
  );
}

export default App;