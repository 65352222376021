import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth.service';
import useBodyClass from '../hooks/useBodyClass';

const LoginComponent = () => {

  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputs, setInputs] = useState({});

  useBodyClass("login");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true)
    try {
      const result = await authService.login(inputs);
      if (result) {
        navigate('/dashboard');
      }
    } catch (error) {
      alert(error);
    }
    setIsSubmitted(false)
  }

  return (
    <main className="form-signin">
    <form onSubmit={handleSubmit}>
      <img className="" src="/bolmaksan.png" alt="" width="200" />
      <h1 className="h3 mb-4 fw-normal">fason</h1>
  
      <div className="form-floating">
        <input type="text" className="form-control" id="floatingInput" name="username" value={inputs.username || ""} onChange={handleChange} />
        <label htmlFor="floatingInput">Kullanıcı</label>
      </div>
      <div className="form-floating">
        <input type="password" className="form-control" id="floatingPassword" name="password" value={inputs.password || ""} onChange={handleChange} />
        <label htmlFor="floatingPassword">Parola</label>
      </div>
  
      <div className="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" /> Hatırla
        </label>
      </div>
      <button className="w-100 btn btn-lg btn-primary" type="submit">Giriş</button>
      <p className="mt-5 mb-3 text-muted">&copy; bolmaksan</p>
    </form>
  </main>
  )
}

export default LoginComponent