class AppUtils {
    static stringToInt(str) {
        let num = parseInt(str, 10);
        if (isNaN(num)) {
            return 0;
        }
    
        return num;
    }

    static docNoToId(docNo) {
        return docNo - 1000000000;
    }

    static idToDocNo(id) {
        return id + 1000000000;
    }

    static moneyFormat(money) {
        return Number(money).toFixed(2)
    }

  }
  
  export default AppUtils;
  