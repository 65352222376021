import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import http from "../../utils/http-client";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { status_options } from '../../data/tracking';

const ManingDetailComponent = () => {
    const { id } = useParams();
    let totalAmount = 0;
    let totalPrice = 0;

    const [childTrackings, setChildTrackings] = useState([]);
    const [tracking, setTracking] = useState(null);
    const [loading, setLoading] = useState(true);

    const { control, register, handleSubmit, reset } = useForm({
        defaultValues: tracking,
    });

    useEffect(() => {
        http.get("tracking/" + id).then(res => {
            setTracking(res.data);
            reset(res.data);
            setLoading(false);
        });
    }, [id, reset]);

    const onSubmit = (data) => {
        data.status = data.status.value;

        http.put("/tracking/" + id, data).then(res => {
            toast.success('Üretim bilgisi gücellendi!');
        });
    };

    const getType = (t) => {
        if (t === "out") {
            return "Çıkış";
        } else if (t === "in") {
            return "Giriş";
        } else {
            return "İptal";
        }
    }

    return (
        <>
            {
                loading ?
                    <div id="loading-overlay">
                        <div id="loading-overlay-text">
                            <img src='/loading.gif' />
                        </div>
                    </div> : '' 
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Üretim Detayı</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    {
                        tracking?.parent_id != null ?
                        <Link to={`/dashboard/manufacturings/detail/${tracking?.parent_id}`} className="btn btn-sm btn-outline-secondary">Üretime Dön</Link>
                        : ''
                    }
                    <Link to="/dashboard/manufacturings/in" className="btn btn-sm btn-outline-secondary ml-3">Fason Giriş</Link>
                    <Link to="/dashboard/manufacturings/out" className="btn btn-sm btn-outline-secondary ml-3">Fason Çıkış</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <label htmlFor="document_no" className="col-sm-3 col-form-label">Belge No</label>
                            <div className="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="document_no" value={tracking?.id + 1000000000} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tracking_type" className="col-sm-3 col-form-label">Türü</label>
                            <div className="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="tracking_type" value={getType(tracking?.tracking_type)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="customer_id" className="col-sm-3 col-form-label">Üretici</label>
                            <div className="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={tracking?.customer?.title} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="created_date" className="col-sm-3 col-form-label">Tarih</label>
                            <div className="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" value={tracking?.created_date} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="product_id" className="col-sm-3 col-form-label">Ürün</label>
                            <div className="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={tracking?.product?.name} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="num_of_products" className="col-sm-3 col-form-label">Ürün Adeti</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" {...register("num_of_products")}/>
                            </div>
                        </div>
                        
                        <div className="row mb-3">
                            <label htmlFor="status" className="col-sm-3 col-form-label">Durum</label>
                            <div className="col-sm-2">
                                <Controller
                                    control={control}
                                    name="status"
                                    render={() => (
                                        <Select
                                            options={status_options}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="notes" className="col-sm-3 col-form-label">Not</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" rows="3" {...register("notes")}></textarea>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Güncelle</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ManingDetailComponent