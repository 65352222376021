import React, { useState, useEffect } from 'react';
import http from "../../../utils/http-client";
import { Link } from 'react-router-dom';
import AppUtils from '../../../utils/app';

const ManingCancelListComponent = () => {
    const [manings, setManings] = useState([]);
    const [filteredManings, setFilteredManings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        http.get("tracking/all/cancel").then(res => {
            res.data = res.data.map((man) => {
                man.id = AppUtils.idToDocNo(man.id);
                return man;
            });
            setManings(res.data);
            setFilteredManings(res.data);
            setLoading(false);
        });
    }, []);

    const filterTracking = (e) => {
        const searchTerm = e.target.value;

        setFilteredManings(manings.filter((c) => {
            let name = e.target.name.split("##");
            if (name.length === 1) {
                return String(c[name[0]]).toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return String(c[name[0]][name[1]]).toLowerCase().includes(searchTerm.toLowerCase());
            }
        }));
    };

    return (
        <>
            {
                loading ?
                    <div id="loading-overlay">
                        <div id="loading-overlay-text">
                            <img src='/loading.gif' />
                        </div>
                    </div> : '' 
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Fason İptal</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Link to="/dashboard/manufacturings/cancel/new" className="btn btn-sm btn-outline-secondary">Yeni Fason İptal</Link>
                </div>
            </div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Belge No</th>
                        <th scope="col">Üretici</th>
                        <th scope="col">İptal Tarihi</th>
                        <th scope="col">Ürün</th>
                        <th scope="col">Adet</th>
                        <th scope="col">Birim Fiyat</th>
                        <th scope="col">Tutar</th>
                        <th scope="col">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="text" name="id" onChange={(e) => filterTracking(e)} style={{width: "100px"}}/></td>
                        <td><input type="text" name="customer##name" onChange={(e) => filterTracking(e)} style={{width: "150px"}}/></td>
                        <td></td>
                        <td><input type="text" name="product##name" onChange={(e) => filterTracking(e)} style={{width: "100px"}}/></td>
                        <td colSpan={7}></td>
                    </tr>
                    {
                        filteredManings.map(man => (
                            <tr key={man.id}>
                                <td>{man.id}</td>
                                <td>{man.customer.title}</td>
                                <td>{man.created_date}</td>
                                <td>{man.product.name}</td>
                                <td>{man.num_of_products}</td>
                                <td>{man.product.price} TL</td>
                                <td>{AppUtils.moneyFormat(man.num_of_products * man.product.price)} TL</td>
                                <td>
                                    <Link to={`/dashboard/manufacturings/detail/${AppUtils.docNoToId(man.id)}`} className="btn btn-sm btn-outline-primary">Detay</Link>
                                    <Link to={`/dashboard/manufacturings/detail/${AppUtils.docNoToId(man.id)}`} className="btn btn-sm btn-outline-primary">Yazdır</Link>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    )
}

export default ManingCancelListComponent